.login-header {
  scale: 1.5;
  display: flex;
  width: 100%;
  min-height: 60px;
  justify-content: center;
  align-items: center;
}

.login-icon {
  width: 30px !important;
  height: 30px !important;
  margin-right: 6px !important;
  padding: 1px !important;
  border: none;
}

.login-content {
  min-height: 175px;
  display: flex;
  align-items: center;
}

.login-instructions {
  padding: 5px;
  font-size: 1.2em;
  line-height: 1.5em;
  font-weight: 600;
  text-align: center;
  width: 100%;
}

.wordmark.login-wordmark {
  fill: var(--reversible-black);
}

.login-form-body {
  width: 100%;
  padding: 1rem;
}

.login-code-validation {
  line-height: 1.65;
}