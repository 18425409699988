div.generate-report-select-format {
  text-align: center;
}

div.generate-report-select-format-label {
  margin-top: 15px;
  font-weight: 600;
  font-size: var(--font-increase-1);

}

div.generate-report-select-format-pdf {
  margin-bottom: 45px;
  margin-top: 35px;
}

button.button-basic.generate-report-select-format-button {
  min-width: 180px;
}