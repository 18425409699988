.basic-panel {
  padding: .65em;
}

.basic-panel.framed {
  border: 1px solid var(--general-border-color);
  box-shadow: var(--panel-shadow);
  border-radius: var(--form-border-radius);
}

.basic-panel.filled {
  background-color: var(--panel-bg-color) !important;
  border-radius: var(--form-border-radius);
}

.basic-panel.control {
  border: 1px solid var(--general-border-color);
  background-color: var(--input-bg-color);
  min-height: 30px;
  border-radius: var(--input-border-radius);
  font-size: .9em;
  margin-top: 5px;
  cursor: default;
  padding: 0px;
  margin-bottom: 0px;
}
