.input-control-text-container {
    display: flex;
}

input[type="text"].input-control {
    line-height: 28px;
    padding-left: 6px;
    padding-bottom: 2px;
    border-radius: var(--input-border-radius);
    border: 1px solid var(--input-border-color);
    background-color: var(--input-bg-color);
    color: var(--body-color);
    font-size: var(--font-reduce-2);
    font-family: var(--body-font);
    box-sizing: border-box;
}

input[type="text"].input-control::placeholder {
    color: var(--app-placeholder-color);
}

input[type="text"].input-control:disabled {
    color: var(--app-placeholder-color);
}

input[type="text"].input-control:hover,
input[type="text"].input-control:active,
input[type="text"].input-control:focus
{
    border-color: var(--customer-selector-border);
}

input[type="text"].input-control:disabled:hover {
    border-color: var(--general-border-color);
}

.input-text-label {
    font-weight: 500;
    line-height: 30px;
    height: 30px;
    margin-right: 5px;
    font-size: var(--font-reduce-2);
    text-align: right;
    white-space: nowrap;
}

.input-text-label.required::before {
    content: "* ";
    color: var(--app-failure-color);
    font-weight: bold;
}