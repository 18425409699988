.pick-list-pane {
  margin: .5rem 1rem .5rem 1rem;
}

.pick-list-pane-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

}
