.basic-table-container {
  padding-left: 2px;
  padding-right: 2px;
}

.basic-table-container.bordered {
  border: var(--general-border);
  border-radius: var(--form-border-radius);
  padding-left: 0px;
  padding-right: 0px;
}

.basic-table-container.filled {
  background-color: var(--panel-bg-color) !important;
  border-radius: var(--form-border-radius);
}

.basic-table-title {
  font-size: 1.1em;
  font-weight: 600;
  padding: 0px 0px 12px 0px;
  border-bottom: var(--general-border);
}

.basic-table {
  background-color: var(--body-bg-color);
  border: none;
  border-collapse: collapse;
}

.basic-table th,
.basic-table td {
  padding-left: 7.5px;
  padding-right: 7.5px;
  border-collapse: collapse;
  box-sizing: border-box;
  text-align: left;
  border: none;
  caption-side: bottom;
}

.basic-table th {
  font-size: .9em;
}

.basic-table.lined th,
.basic-table.lined td {
  border-bottom: var(--general-border);
}

.basic-table tr {
  line-height: 1.75em;
  border: none;
  border-collapse: collapse;
  caption-side: bottom;
  box-sizing: border-box;
}

.basic-table tr.basic-table-header-row,
.basic-table tr.basic-table-header-row:hover,
.basic-table.banded tr.basic-table-header-row:hover {
  background-color: var(--form-row-hover);
  line-height: 1.5em;
}

.basic-table.banded tr:nth-child(even) {
  background-color: #88888810;
}

.basic-table tr:hover,
.basic-table.banded tr:hover {
  background-color: #9992;
}

.basic-table tr.basic-table-header-row.no-title th:first-child {
  border-top-left-radius: var(--form-border-radius);
}

.basic-table tr.basic-table-header-row.no-title th:last-child {
  border-top-right-radius: var(--form-border-radius);
}
