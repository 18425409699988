.chart-library-controls {
    width: 260px;
    padding-top: 8px;
    padding-left: 4px;
    padding-bottom: 3px;
    min-width: 260px;
    height: calc(100vh - 166px);
    overflow-y: hidden;
    overflow-x: hidden;
    padding: .5rem;
    box-sizing: border-box;
}

select.chart-library-select,
select.chart-library-select:active,
select.chart-library-select:focus {
    width: calc(100% - 2px);
    margin-left: 3px;
}

.chart-library-controls div.timepicker-control {
    margin-left: 3px;
}