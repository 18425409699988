.time-bucket-selector {
  padding-left: .5rem;
  padding-right: .5rem;
  margin-top: 7px;
}

.time-bucket-selector-toggle-row {
  font-weight: 600;
  padding-bottom: 10px;
  font-size: var(--font-reduce-2);
  display: flex;
  align-items: center;
  margin-left: 10px;
  white-space: nowrap;
}

.time-bucket-selector-row-label {
  min-width: 90px;
  white-space: nowrap;
}

.time-bucket-whats-this {
  margin-left: 5px;
}
