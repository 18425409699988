.footer {
  border-top: 1px var(--general-border-color) solid;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  height: var(--footer-height);
  line-height: 45px;
  font-size: var(--font-reduce-2);
  color: var(--footer-color);
  background-color: var(--footer-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-app-version {
  cursor: pointer;
}

.footer-app-version:hover {
  text-decoration: underline;
}

.username-link {
  color: inherit;
  text-decoration: none;
  border: none !important;
  padding: 0px !important;
  margin: 0px !important;
  background-color: inherit !important;
  cursor: pointer;
  font-family: var(--body-font);
  font-size: 1em;
}

.username-link:hover {
  color: inherit;
  text-decoration: underline;
}
