div.pillbox-control {
  background-color: var(--form-row-hover);
  border-radius: 8px;
  display: flex;
  max-height: 32px;
  min-height: 32px;
  height: 32px;
  align-items: center;
  box-sizing: border-box;
}

div.pillbox-control div.pillbox-control-pill,
div.pillbox-control a.pillbox-control-pill {
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  margin-left: 2px;
  margin-right: 2px;
  height: 28px;
  min-height: 28px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--body-color)
}

div.pillbox-control div.pillbox-control-pill:hover,
div.pillbox-control a.pillbox-control-pill:hover {
  box-shadow: inset 0 0 0 10em rgba(128, 128, 128, 0.1);
  text-decoration: none;
}

div.pillbox-control div.pillbox-control-pill.pillbox-control-pill-selected {
  cursor: default;
  font-weight: 600;
  background-color: var(--body-bg-color);
}

div.pillbox-control div.pillbox-control-pill.pillbox-control-pill-selected:hover {
  box-shadow: none;
}

div.pillbox-control.pillbox-control-tall {
  max-height: 42px;
  min-height: 42px;
  height: 42px;
}

div.pillbox-control.pillbox-control-tall div.pillbox-control-pill {
  max-height: 36px;
  min-height: 36px;
  height: 36px;
  font-size: 16px;
}
