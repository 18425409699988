.basic-modal-header-icon {
  height: 23px;
  width: 23px;
  margin-left: .25rem;
  margin-right: 1rem;
}

.basic-modal-header h5 {
  margin-right: auto;
}

.scrollable-modal {
  overflow-y: scroll;
  max-height: calc(100vh - 75px);
}

.basic-modal-mask {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 1, 2, 0.6);
  display: table;
}

.basic-modal-mask.basic-modal-closing {
  opacity: 0;
  transition: opacity 300ms ease;
}

.basic-modal-wrapper {
  display: table-cell;
  vertical-align: top;
  padding-top: 60px;
}

.basic-modal-wrapper-fs {
  padding-top: 0px;
}

.basic-modal-container {
  max-width: 420px;
  margin: 0px auto;
  padding: 0px;
  background-color: var(--body-bg-color);
  border-radius: var(--panel-border-radius);
  border: 1px var(--general-border-color) solid;
  box-shadow: 0 0 50px 40px var(--modal-shadow);
  transition: all 0.2s ease;
  color: var(--body-color);
  line-height: normal;
}

.basic-modal-header h5 {
  margin-top: 4px;
}

.basic-modal-footer {
  border-top: 1px var(--general-border-color) solid;
  padding: 12px 10px 12px 10px;
  justify-content: right;
  display: flex;
  position: relative;
}

.basic-modal-footer.basic-modal-footer-stacked {
  display: block;
}

.basic-modal-footer-spreaded {
  justify-content: space-between;
}

.basic-modal-header {
  margin-top: 0;
  padding: 10px 0px 0px 10px;
  border-bottom: 1px var(--general-border-color) solid;
  display: flex;
  align-items: center;
  padding-bottom: .5rem;
}

.basic-modal-body {
  min-height: 140px;
}

.basic-modal-container.basic-modal-closing {
  transition: scale 300ms, opacity 300ms;
  scale: 1.4;
  opacity: 0;
}

.basic-modal-sm {
  width: 375px;
  max-width: 375px;
  min-width: 370px;
}

.basic-modal-475 {
  width: 475px;
  max-width: 475px;
  min-width: 475px;
}

.basic-modal-smmd {
  width: 600px;
  max-width: 600px;
  min-width: 600px;
}

.basic-modal-md {
  width: 685px;
  max-width: 685px;
  min-width: 685px;
}

.basic-modal-lg {
  width: 900px;
  max-width: 900px;
  min-width: 900px;
}

.basic-modal-xl {
  width: 1125px;
  max-width: 1125px;
  min-width: 1125px;
}

.basic-modal-fs {
  width: 100vw;
  max-width: 100vw;
  min-width: 100vw;
  height: calc(100vh - var(--header-height));
  margin-top: var(--header-height);
}

.basic-modal-required-note {
  position: absolute;
  left: 15px;
  top: 20px;
  font-size: var(--font-reduce-2);
}

.basic-modal-required-note::before {
  color: var(--app-failure-color);
  content: '* ';
  font-weight: bold;
  margin-right: 1px;
}

.modal-column-divider {
  width: 1px;
  border-left: var(--general-border);
}

.subsection-header {
  font-size: var(--font-reduce-2);
  font-weight: 500;
  margin-bottom: 4px;
}

.status-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.status-banner .status-banner-icon {
  margin-right: 10px;
  height: 20px;
}

.status-banner.status-banner-large {
  display: block;
  text-align: center;
  font-size: var(--font-increase-2);
}

.status-banner.status-banner-large .status-banner-icon {
  height: 54px;
  margin-bottom: 10px;
  margin-right: 0px;
}

.status-banner.status-banner-success {
  color: var(--app-success-color);
}

.status-banner.status-banner-failed {
  color: var(--app-failure-color);
}

.status-banner.status-banner-caution {
  color: var(--app-caution-color);
}

.status-banner.status-banner-muted {
  color: var(--app-subtle-color);
}

.status-banner .status-banner-label {
  font-size: var(--font-increase-2);
}

.status-banner.status-banner-large .status-banner-label {
  font-weight: 600;
  font-size: inherit;
}

div.basic-modal-x-closer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  box-sizing: border-box;
  min-width: 35px;
  max-width: 35px;
  max-height: 35px;
  border-radius: 18px;
  margin-right: 5px;
  margin-top: -11px;
}

div.basic-modal-x-closer:hover {
  background-color: var(--form-row-hover);
  cursor: pointer;
}

div.basic-modal-x-closer .basic-modal-x-closer-icon {
  height: 20px;
  color: var(--app-subtle-color);
}

div.basic-modal-x-closer:hover .basic-modal-x-closer-icon {
  color: var(--app-link-color);
  opacity: .7;
}