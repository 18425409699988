.report-run-detail-body {
  min-height: 250px;
  padding: 15px;
}

.report-file-list {
  overflow-y: auto;
  margin-left: 4px;
}

.report-file-list-row {
  font-size: var(--font-reduce-2);
  line-height: 30px;
  overflow-x: hidden;
  white-space: nowrap;
}

.report-definition-detail-runs-body {
  margin-left: 1rem;
  margin-right: 1rem;
}

.report-definition-detail-row {
  line-height: 30px;
}