
.logo {
  width: 30px !important;
  margin-right: 6px !important;
  padding: 1px !important;
  border: none;
}

.wordmark {
  fill: white;
  width: 125px;
  border: none;
  padding: none;
  margin-right: 12px;
  margin-left: 7px;
  margin-top: 3px;
}

.nav-header {
  min-height: var(--header-height);
  max-height: var(--header-height);
  height: var(--header-height);
}

.nav-container {
  width: 100% !important;
  padding-left: 0px;
  padding-right: 0px;
  min-height: 42px;
  max-height: 42px;
  height: 42px;
  margin: auto;
}

.nav-inner {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  justify-content: space-between;
  text-align: start;
}

.nav-logos {
  width: 100%;
  align-items: center;
  display: flex;
}

nav.navbar {
  display: flex;
  background-color: var(--nav-bg-color);
  position: fixed;
  z-index: 24;
  box-shadow: var(--nav-box-shadow);
  border-bottom: 1px solid var(--nav-border-color);
  padding: 6px 12px 5px 12px !important;
  min-width: 350px;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: 42px;
  max-height: 42px;
  box-sizing: border-box;
}

.nav-right-area {
  display: flex;
}

.navbar-icon {
  height: 20px;
  width: 20px;
  padding: 2px;
  cursor: pointer;
  color: var(--nav-icon-color);
}

.navbar-icon-active {
  color: var(--app-light-blue) !important;
}

@media (hover: hover) {
  .navbar-icon:hover {
    color: var(--app-light-blue);
  }
}


span.customer-selector {
  color: var(--app-extra-light-gray);
}

select.customer-selector,
select.customer-selector:focus,
select.customer-selector:active {
  margin-left: 3px !important;
  height: 30px;
  padding: 4px 24px 4px 8px;
  color: var(--customer-selector-color);
  font-size: var(--font-reduce-1);
  border: 1px var(--customer-selector-border) solid;
  border-radius: calc(var(--form-border-radius) - 3px);
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  appearance: none;
  background-color: var(--form-control-bg-color);
  background-repeat: no-repeat;
  background-position-x: calc(100% - 6px);
  background-position-y: 9px;
  background-size: 12px 10px;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23545a60%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");

}

.non-public-banner {
  color: #ea0000;
  font-weight: 600;
  background-color: #363432;
  padding: 5px 13px 5px 13px;
  border-radius: 12px;
  font-size: var(--font-reduce-3);
  text-shadow: 0px 0px 6px #111;
}
