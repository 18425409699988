.reports-all-definitions-list .basic-table-container {
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
}


.reports-all-definitions-list .basic-table {
  width: 100%;
}

.reports-all-definitions-list .basic-table tr th {
  padding-top: 4px;
  padding-bottom: 4px;
}

.reports-all-definitions-list .basic-table tr td {
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}
