.radio-group {
  display: flex;
  align-items: center;
}

.radio-group .radio-group-label {
  font-weight: 500;
  line-height: 30px;
  height: 30px;
  margin-right: 15px;
  font-size: var(--font-reduce-2);
  text-align: right;
}

.radio-group .radio-group-label.required::before {
  content: "* ";
  color: var(--app-failure-color);
  font-weight: bold;
}