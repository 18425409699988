.timepicker-control {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.timepicker-box {
  min-height: 32px;
}

.timepicker-box-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 33px;
  padding-left: 7px;
  margin-right: 7px;
}


.timepicker-control-label {
  font-weight: 500;
  margin-right: 5px;
  font-size: var(--font-reduce-2);
  text-align: right;
  padding-top: 5px;
}