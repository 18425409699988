div.checkbox-with-text-input {
  display: flex !important;
  align-items: center;
}

.checkbox-with-text-input-label {
  font-weight: 500;
  line-height: 30px;
  height: 30px;
  margin-right: 5px;
  font-size: var(--font-reduce-2);
  text-align: right;
  white-space: nowrap;
}

.checkbox-with-text-input-input {
  min-width: 60px;
  max-width: 60px;
  text-align: right;
  padding-right: 5px;
  margin-left: 15px;
  height: 26px;
  font-size: var(--font-reduce-2);
}
