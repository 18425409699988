.flash-message-container {
  background-color: var(--data-ok-bg-color);
  color: var(--app-green);
  padding-left: 5px;
  border: 1px solid var(--app-green);
  min-height: 42px !important;
  max-height: 42px !important;
  height: 42px !important;
  margin: auto;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 102;
  border-collapse: collapse;
  opacity: 1;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
}

.flash-message-container.flash-message-container-closing {
  opacity: 0;
}

.flash-message-inner {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  min-width: 1000px;
  width: 1200px;
  max-width: 1200px;
}

.flash-message-container-error {
  background-color: var(--data-failure-bg-color);
  color: var(--app-failure-color);
  border: 1px solid var(--app-failure-color);
}

.flash-message-container-warning {
  background-color: var(--data-warning-bg-color);
  color: var(--app-caution-color);
  border: 1px solid var(--app-caution-color);
}

.flash-message-closer {
  opacity: .65;
  border-radius: 100px;
  border: 1px solid var(--app-green);
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8.5px;
  right: 0px;
}

.flash-message-container.flash-message-container-warning .flash-message-closer {
  border-color: var(--app-caution-color);
  opacity: .8;
}

.flash-message-container.flash-message-container-error .flash-message-closer {
  border-color: var(--app-failure-color);
  opacity: .8;
}

.flash-message-container .flash-message-closer:hover {
  border-width: 2px;
  cursor: pointer;
  opacity: 1;
}

.flash-message-close-button {
  appearance: none;
  text-decoration: none;
  border: none;
  background-color: inherit;
  color: var(--app-green);
  padding: 0px;
  margin: 0px;
  margin-top: -1px;
}

.flash-message-container.flash-message-container-warning .flash-message-close-button {
  color: var(--app-caution-color);
}

.flash-message-container.flash-message-container-error .flash-message-close-button {
  color: var(--app-failure-color);
}

.flash-message-body {
  display: flex;
  max-width: 1116px;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.flash-icon {
  margin-right: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.flash-icon-svg {
  height: 20px;
  width: 20px;
  padding-bottom: 2px;
}