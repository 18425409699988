textarea.input-control {
    line-height: 28px;
    padding-left: 6px;
    padding-bottom: 2px;
    border-radius: var(--input-border-radius);
    border: 1px solid var(--input-border-color);
    background-color: var(--input-bg-color);
    color: var(--body-color);
    font-size: var(--font-reduce-2);
    box-sizing: border-box;
    font-family: var(--body-font);
}

textarea.input-control.input-control-error,
textarea.input-control.input-control-error:active,
textarea.input-control.input-control-error:hover,
textarea.input-control.input-control-error:focus {
    color: var(--app-failure-color);
    border: 1px solid var(--app-failure-color);
}

textarea.input-control:hover,
textarea.input-control:active,
textarea.input-control:focus
{
    border-color: var(--customer-selector-border);
}

textarea.input-control:disabled:hover {
    border-color: var(--general-border-color);
}

textarea.input-control::placeholder {
    color: var(--app-placeholder-color);
}

textarea.input-control:disabled {
    color: var(--app-placeholder-color);
}

.input-control-textarea-container {
    display: flex;
}

.textarea-label {
    font-weight: 500;
    line-height: 30px;
    height: 30px;
    margin-right: 5px;
    font-size: var(--font-reduce-2);
    text-align: right;
}

.input-control-textarea-footnote {
    font-size: var(--font-reduce-2);
    margin-left: 15px;
    color: var(--app-subtle-color);
}

.textarea-label.required::before {
    content: "* ";
    color: var(--app-failure-color);
    font-weight: bold;
}