:root {
  --query-pane-height-open: fit-content;
  --query-pane-height-closed: 35px;
}

.query-pane {
  transition: height 120ms;
}

.query-pane-open {
  padding-bottom: .5rem !important;
}

.query-pane-row-label {
  min-width: 48px;
  white-space: nowrap;
}

.query-pane-sitenode-selector {
  padding-left: .5rem;
  padding-right: .5rem;
  margin-top: 5px;
}

div.input-control.query-pane-select {
  min-height: 26px;
  max-height: 26px;
  height: 26px;
}

select.input-control.query-pane-select {
  min-height: 30px;
  max-height: 30px;
  height: 30px;
}