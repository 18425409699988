.modal-save-chart {
  padding: 1rem 1rem .5rem 1rem;
  min-height: 325px;
  height: 325px;
  max-height: 325px;
  box-sizing: border-box;
}

.modal-save-chart-body {
  line-height: 1.9;
  padding: .5rem;
}

.status-banner.chart-save-in-progress {
  padding-top: 30px;
  font-size: var(--font-increase-4);
}

.status-banner.chart-save-success,
.status-banner.chart-save-failure {
  margin-top: 20px;
}

.chart-save-failed-content {
  line-height: 1.9;
  padding: .5rem;
  margin-bottom: 15px;
  text-align: center;
}