.leftnav {
  top: 55px;
  margin-left: 5px;
  width: 190px;
  height: calc(100vh - 20px - var(--header-height) - var(--footer-height));
  position: fixed;
}

.leftnav-icon {
  margin-right: 9px;
}
