.fs-toggle {
  position: absolute;
  z-index: 22;
  border-radius: 3px;
  padding: 14px !important;
  top: 3px;
  right: -5px;
  background-color: none;
  color: var(--app-text-color);
  cursor: pointer;
  opacity: 0;
  min-width: 25px;
  max-width: 25px;
  width: 25px;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease, background-color 0.2s ease;
  display: flex;
  box-sizing: border-box;
}

.fs-toggle.fs-toggle-active,
.fs-toggle.fs-toggle-active.fs-toggle-touch {
  color: var(--app-link-color);
  right: -1px;
}

.fs-toggle.fs-toggle-touch {
  opacity: .7;
}
