.panel-banner {
  padding: 8px 10px 8px 10px;
  border-bottom: var(--general-border);
  font-size: 1.1em;
  display: flex;
  align-items: center;
}

.panel-banner-icon {
  margin-right: 10px;
}

.panel-banner-ok {
  color: var(--app-success-color);
  box-shadow: inset 0em 0em 0em 10em rgba(0, 204, 0, 0.05);
}

.panel-banner-failure {
  color: var(--app-failure-color);
  box-shadow: inset 0em 0em 0em 10em rgba(204, 0, 0, 0.1);
}
