:root {
  /* globals (apply to all themes) */
  --body-font: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  --font-increase-05: 1.05em;
  --font-increase-1: 1.10em;
  --font-increase-2: 1.2em;
  --font-increase-3: 1.30em;
  --font-increase-4: 1.5em;
  --font-increase-5: 1.6em;
  --font-increase-6: 1.8em;
  --font-increase-7: 2.00em;

  --font-size-base: 1em;
  --font-size-button: var(--font-size-base);

  --font-reduce-05: 0.975em;
  --font-reduce-1: 0.95em;
  --font-reduce-1a: 0.93em;
  --font-reduce-1b: 0.9em;
  --font-reduce-2: 0.875em;
  --font-reduce-3: 0.825em;
  --font-reduce-4: 0.75em;

  --form-border-radius: .45em;
  --input-border-radius: .25em;
  --button-border-radius: 1.5em;
  --panel-border-radius: .45em;

  --header-height: 43px;
  --footer-height: 57px;

  --reversible-dark-color: var(--body-color);
  --reversible-light-color: var(--body-bg-color);
  --reversible-black: white;
  --reversible-white: black;

  --app-blue: #0577F9;
  --app-light-blue: #48a6f0;
  --app-green: #24b239;
  --app-green-bright: #38c64d;
  --app-red: #cc2222;
  --app-yellow: #f0c030;
  --app-off-white: #f5f4f3;
  --app-charcoal: #141719;
  --app-light-charcoal: #161a1d;
  --app-extra-light-gray: #f0f0f0;
  --app-light-gray: #e3e4e6;
  --app-medium-gray: #c0c2c4;

  --app-accent-color: var(--app-green);
  --app-subtle-color: #666666cc;

  --button-background: #00000000;

  --general-hover: #8883;
  --general-border: 1px solid var(--general-border-color);
  --input-border: 1px solid var(--input-border-color);

  --panel-bg-color: var(--form-row-hover);

  /* Default Color Values - darkMode */

  --app-lm-black: var(--app-extra-light-gray);
  --body-bg-color: var(--app-charcoal);
  --app-nav-bg-color: var(--app-light-charcoal);
  --body-color: #babcbd;
  --general-border-color: #343638;
  --app-cell-color: #222426;
  --app-failure-color: #aa0000;
  --app-header-color: var(--app-extra-light-gray);
  --app-highlight-color: var(--app-link-color);
  --app-highlight-border-color: var(--app-link-color);
  --app-highlight-hover-bg-color: var(--app-link-color);
  --app-highlight-active-bg-color: var(--app-blue);
  --app-link-color: var(--app-light-blue);
  --app-placeholder-color: #404244;
  --app-subtle-color: #626466;
  --app-success-color: var(--app-green);
  --app-caution-color: #ddaa00;

  --button-blue-bg-color: var(--app-link-color);
  --button-green-bg-color: var(--app-green);
  --button-red-bg-color: #aa0000;
  --button-yellow-bg-color: var(--app-yellow);

  --customer-selector-color: var(--app-medium-gray);
  --customer-selector-border: #474849;

  --data-failure-bg-color: #2c1d15;
  --data-ok-bg-color: #152c1d;
  --data-warning-bg-color: #2c2c12;

  --flash-info-bg-color: #aaffbbe0;
  --flash-error-bg-color: #ddccccdd;

  --footer-bg-color: var(--body-bg-color);
  --footer-color: #646668;

  --form-row-hover: #242628;
  --input-bg-color: #080a0c;
  --input-border-color: #343638;

  --chart-bg-color: var(--app-charcoal);
  --menu-hover-bg-color: var(--form-row-hover);
  --menu-bg-color: #0a0e10;
  --select-menu-bg-color: #222322;

  --modal-shadow: rgba(0, 4, 8, 1);

  --nav-hover-bg-color: #2f3133;
  --nav-bg-color: var(--app-charcoal);
  --nav-box-shadow: 0px 3px 6px black;
  --nav-border-color: #292929;
  --nav-icon-color: var(--app-green);

  --pane-box-shadow: 3px 3px 6px black;

  --button-shadow: 2px 2px 2px black;

  --widget-weather-background: #101820;
  --widget-map-background: #102810;
  --widget-alerts-background: #cc000033;
}

[data-theme="lightMode"] {
  /* Color Values - lightMode */

  --app-lm-black: black;
  --body-bg-color: #fffdfb;
  --app-nav-bg-color: var(--app-light-gray);
  --body-color: #464544;
  --general-border-color: #acabaa;
  --app-cell-color: #d2d1d0;
  --app-failure-color: #aa0000;
  --app-header-color: var(--body-color);
  --app-highlight-color: var(--app-blue);
  --app-highlight-border-color: var(--app-blue);
  --app-link-color: #3388FF;
  --app-highlight-hover-bg-color: var(--app-link-color);
  --app-placeholder-color: var(--general-border-color);
  --app-subtle-color: #999795;
  --app-success-color: var(--app-green);
  --app-caution-color: #e0a000;

  --reversible-dark-color: var(--body-color);
  --reversible-light-color: var(--body-bg-color);
  --reversible-black: black;
  --reversible-white: white;

  --customer-selector-color: var(--app-light-gray);
  --customer-selector-border: #606468;

  --data-ok-bg-color: #d0eed0;
  --data-failure-bg-color: #f0c8c8;
  --data-warning-bg-color: #f0f0c8;

  --flash-info-bg-color: #aaffbbe0;
  --flash-error-bg-color: #ddccccdd;

  --footer-bg-color: #121616;
  --footer-color: #999999;

  --form-row-hover: #e9e8e7;
  --input-bg-color: #f2f1f0;
  --input-border-color: #acabaa;

  --chart-bg-color: #fcfaf8;

  --menu-hover-bg-color: #c5c4c366;
  --menu-bg-color: #e8e6e4;
  --select-menu-bg-color: #606060;

  --modal-shadow: rgba(51, 51, 51, 1);

  --nav-hover-bg-color: #cfcecd;
  --nav-bg-color: var(--app-charcoal);
  --nav-box-shadow: 0px 3px 5px var(--general-border-color);
  --nav-border-color: #2a2928;
  --nav-icon-color: var(--app-green);

  --pane-box-shadow: 3px 3px 6px var(--general-border-color);
  --button-shadow: 2px 2px 3px var(--general-border-color);

  --widget-weather-background: #f0f8ff;
  --widget-map-background: #e8f8e8;
  --widget-alerts-background: #cc000022;
}

[data-theme="highContrastMode"] {
  /* Color Values - highContrastMode */

  --panel-bg-color: var(--menu-bg-color);

  --app-lm-black: black;
  --body-bg-color: white;
  --body-color: black;
  --general-border-color: #333;
  --app-cell-color: #e0e0e0;
  --app-nav-bg-color: #f4f4f4;
  --app-failure-color: #aa0000;
  --app-header-color: var(--body-color);

  --reversible-dark-color: black;
  --reversible-light-color: white;
  --reversible-black: black;
  --reversible-white: white;

  --app-highlight-color: #0033FF;
  --app-highlight-border-color: #0033FF;
  --app-highlight-hover-bg-color: #0033FF;

  --app-placeholder-color: #a0a0a0;
  --app-subtle-color: #666666;
  --app-success-color: var(--app-green);
  --app-caution-color: #bb8000;

  --app-link-color: #0033FF;
  --button-blue-bg-color: #0033FF;

  --customer-selector-color: var(--app-extra-light-gray);
  --customer-selector-border: #909498;

  --data-ok-bg-color: #ffffff;
  --data-failure-bg-color: #ffffff;
  --data-warning-bg-color: #ffffff;

  --flash-info-bg-color: #aaffbbe0;
  --flash-error-bg-color: #ddccccdd;

  --footer-bg-color: #121616;
  --footer-color: #999999;

  --form-row-hover: #e9e8e7;
  --input-bg-color: #fdfdfd;
  --chart-bg-color: white;

  --menu-hover-bg-color: #d8d8d8;
  --menu-bg-color: var(--app-extra-light-gray);
  --select-menu-bg-color: #606060;

  --modal-shadow: rgba(51, 51, 51, 1);

  --nav-hover-bg-color: var(--menu-hover-bg-color);
  --nav-bg-color: var(--app-charcoal);
  --nav-box-shadow: none;
  --nav-border-color: black;
  --nav-icon-color: var(--app-green);

  --pane-box-shadow: none;
  --button-shadow: none;

  --widget-weather-background: white;
  --widget-map-background: #e0e8e0;
  --widget-alerts-background: #ece6e6;

}

/* base html elements */

html {
  position: relative;
  min-height: 100%;
}

body {
  background-color: var(--body-bg-color);
  color: var(--body-color);
  font-size: var(--font-size-base);
  font-family: var(--body-font);
  overflow-x: hidden !important;
  line-height: normal;
  margin: 0;
  display: block;
  text-size-adjust: 100%;
  font-weight: 400;
  box-sizing: border-box;
  text-align: start;
}

header {
  display: block;
  unicode-bidi: isoalte;
}

input, textarea, select {
  outline: none;
  box-shadow: none;
  -webkit-box-shadow: none;
}

input::placeholder, textarea::placeholder {
  font-style: italic;
}

.wide, input.wide, textarea.wide, select.wide, .input-control.wide, div.wide, button.wide {
  width: 100%;
}

p {
  margin-top: 0px;
}

/* end html elements */

.container {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 1rem;
}

.highlight {
  color: var(--app-green);
}

.caution {
  color: var(--app-caution-color);
}

.subtle {
  color: var(--app-subtle-color);
}

.selectable {
  cursor: pointer !important;
}

.framed {
  align-items: center;
  align-content: center;
  border: 1px solid var(--general-border-color);
  border-radius: var(--form-border-radius) !important;
  box-shadow: var(--pane-box-shadow);
  padding: 10px;
}

.login-pane {
  margin-top: 30px;
}

.form-header {
  font-size: var(--font-increase-4);
  font-weight: 500;
  color: var(--app-header-color);
}

hr {
  padding-top: 1.5px !important;
  background-color: var(--general-border-color);
  border: none !important;
  opacity: 1 !important;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

span.input-group-text {
  box-sizing: border-box;
  background-color: var(--app-cell-color);
  border: 1px solid var(--general-border-color) !important;
  color: var(--body-color);
  padding-left: 6px;
  padding-right: 6px;
  font-size: var(--font-reduce-1);
  font-weight: 500;
  height: 38px;
  display: flex;
  align-items: center;
}

input.form-control {
  background-color: var(--input-bg-color);
  border: 1px solid var(--general-border-color) !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  color: var(--body-color) !important;
  padding-left: 7px;
  padding-right: 3px;
}

input.form-control:disabled {
  background-color: var(--input-bg-color);
  color: var(--app-subtle-color) !important;
}

input.rounded {
  border-radius: var(--input-border-radius) !important;
}

input.form-control:focus {
  background-color: var(--input-bg-color);
  border: 1px solid var(--general-border-color) !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

input.form-control::-webkit-input-placeholder {
  color: var(--app-placeholder-color) !important;
}

input.form-control::placeholder {
  color: var(--app-placeholder-color) !important;
}

.add-border-bottom {
  border-bottom: 1px var(--general-border-color) solid;
}

.add-border-top {
  border-top: 1px var(--general-border-color) solid;
}

/* Font Sizing Classes */

.font-reduce-05 {
  font-size: var(--font-reduce-05) !important;
}

.font-reduce-1 {
  font-size: var(--font-reduce-1) !important;
}

.font-reduce-1a {
  font-size: var(--font-reduce-1a) !important;
}

.font-reduce-1b {
  font-size: var(--font-reduce-1b) !important;
}

.font-reduce-2 {
  font-size: var(--font-reduce-2) !important;
}

.font-increase-0 {
  font-size: 1em !important;
}

.font-increase-05 {
  font-size: var(--font-increase-05) !important;
}

.font-increase-1 {
  font-size: var(--font-increase-1) !important;
}

.font-increase-2 {
  font-size: var(--font-increase-2) !important;
}

.font-increase-3 {
  font-size: var(--font-increase-3) !important;
}

.centered {
  align-items: center !important;
  align-content: center !important;
  justify-content: center;
  text-align: center;
}

.form-check-input {
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  margin-top: 2px;
  background-color: var(--input-bg-color);
  border: 1px var(--general-border-color) solid;
}

.form-select {
  height: 32px;
}

.form-select,
.form-select:focus,
.form-select:active {
  appearance: none;
  background-position-y: 9px;
  background-color: var(--form-control-bg-color);
  border: 1px var(--general-border-color) solid;
  border-radius: var(--input-border-radius);
  color: var(--form-control-color);
  background-position-x: calc(100% - 8px);
  background-size: 12px 10px;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  padding: 4px 24px 4px 8px;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 7px);
  background-size: 12px 10px;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23545a60%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
}

.form-select:disabled,
.form-select:disabled:active {
  background-color: var(--form-control-bg-color);
  color: var(--app-subtle-color);
}

.controls-box {
  border-radius: var(--form-border-radius) !important;
  background-color: var(--form-row-hover);
}

.controls-box-row {
  margin-top: 5px;
  margin-bottom: 5px;
}

.controls-box-check-label {
  font-size: var(--font-reduce-1);
}

.controls-box-input-label {
  font-weight: 600;
  font-size: var(--font-reduce-2);
  width: 65px;
  text-align: right;
  padding-right: 6px;
}

.controls-box-input-text {
  padding-top: 1px !important;
}

.controls-box-input,
.controls-box-input:active,
.controls-box-input:focus {
  width: 178px;
  border: 1px solid var(--general-border-color) !important;
  border-radius: calc(var(--form-border-radius) - 2px);
  height: 30px;
  color: var(--body-color);
  font-size: .9em;
  background-color: var(--input-bg-color);
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  padding-left: 7px;
  padding-top: 3.5px;
  margin-right: 5px;
}

.controls-box-select {
  height: 28px;
  width: 174px;
}

.content-pane {
  margin-top: -4px;
  margin-left: 204px;
  animation: fade-in .45s;
}

.input-row-label {
  margin-right: 0px;
}

a,
a:link,
a:visited,
a.normal,
a.normal:link,
a.normal:visited,
.link-normal,
.link-normal:link,
.link-normal:visited
{
  color: var(--app-link-color);
  text-decoration: none;
  cursor: pointer;
}

@media (hover: hover) {

  a:hover,
  a.normal:hover,
  .link-normal:hover,
  a:link:hover {
    color: var(--app-link-color);
    text-decoration: underline;
  }
}

a:active,
a.normal:active,
.link-normal:active
a:link:active {
  color: var(--app-link-color);
  text-decoration: none;
}

a.no-underline,
a:link.no-underline,
a:visited.no-underline,
a:hover.no-underline,
a:active.no-underline {
  text-decoration: none;
}

a.inactive,
a.inactive:link,
a.inactive:visited,
td.inactive a,
td.inactive a:link,
td.inactive a:visited {
  color: var(--app-inactive-color);
  text-decoration: none;
}

@media (hover: hover) {

  a.inactive:hover,
  td.inactive a:hover {
    color: var(--app-inactive-color);
    text-decoration: underline;
  }
}

a.inactive:active,
td.inactive a:active {
  color: var(--app-inactive-color);
  text-decoration: none;
}

.width-35 {
  width: 35px;
  min-width: 35px;
}

.width-50 {
  width: 50px;
  min-width: 50px;
}

.width-60 {
  width: 60px;
  min-width: 60px;
}

.width-65 {
  width: 65px;
  min-width: 65px;
}

.width-85 {
  width: 85px;
  min-width: 85px;
}

.width-95 {
  width: 95px;
  min-width: 95px;
}

.width-155 {
  width: 155px;
  min-width: 155px;
}

.width-235 {
  width: 235px;
  min-width: 235px;
}

.width-fc {
  width: fit-content;
}

.hoverable-text:hover,
.hoverable-icon:hover,
.expand-box-hoverable:hover .hoverable-icon,
.expand-box-hoverable:hover .hoverable-text {
  color: var(--app-link-color);
}

.hoverable-text:hover,
.expand-box-hoverable:hover .hoverable-text {
  text-decoration: underline;
}

.circle-icon {
  color: var(--app-link-color);
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  margin-right: 4px;
  margin-left: 4px;
}

.circle-icon:hover {
  background-color: var(--form-row-hover);
}

.picklist-link {
  color: var(--body-color);
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #FFFFFF00;
  padding: 3px 6px 3px 6px;
  border-radius: 4px;
  margin-left: .5px;
  margin-right: .5px;
}

@media (hover: hover) {
  .picklist-link:hover {
    border: 1px dashed var(--body-color);
    text-decoration: none;
  }
}

.picklist-link:last-child {
  margin-right: 0px;
}

.picklist-link-selected {
  color: var(--body-bg-color);
  background-color: var(--app-lm-black);
}

@media (hover: hover) {
  .picklist-link-selected:hover {
    color: var(--body-bg-color);
    background-color: var(--app-lm-black);
    border: 1px solid #FFFFFF00;
    text-decoration: none;
  }
}

.light-bold {
  font-weight: 600;
}

.rounded-top {
  border-top-left-radius: var(--form-border-radius);
  border-top-right-radius: var(--form-border-radius);
}

.strikethrough {
  text-decoration: line-through;
}

.light-panel-box {
  background-color: var(--input-bg-color);
  border-radius: calc(var(--form-border-radius) - 2px);
  cursor: default;
}

.me-1 {
  margin-right: .25rem;
}

.me-2 {
  margin-right: .5rem;
}

.me-3 {
  margin-right: 1rem;
}

.me-4 {
  margin-right: 1.5rem;
}

.ms-1 {
  margin-left: .25rem;
}

.ms-2 {
  margin-left: .5rem;
}

.ms-3 {
  margin-left: 1rem;
}

.ms-4 {
  margin-left: 1.5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 2rem;
}

.select-flyout {
  white-space: nowrap;
}

.select-flyout-menu {
  padding: 2px;
  border-radius: 4px;
  border: .5px solid #ccc6;
  background-color: var(--select-menu-bg-color);
  color: white;
  backdrop-filter: blur(15px);
  position: absolute;
  top: -20px;
  left: -5px;
  max-width: 166px;
  box-shadow: var(--pane-box-shadow);
  overflow-x: visible;
  z-index: 15;
}

.select-flyout-menu.submenu {
  font-size: var(--font-reduce-1);
  left: 58px;
  z-index: 16;
  /* top: 0px; */
}

.select-flyout-menu.no-check-column ul li {
  padding-left: 3px;
}

.select-flyout-menu.submenu ul li {
  padding-left: 13px !important;
}

.select-flyout-menu.metric-category {
  width: 75px !important;
  max-width: 70px;
  left: -38px;
  top: -10px;
}


.select-flyout-menu ul {
  margin: 0px;
  padding: 2px;
}

.select-flyout-menu ul li {
  border-radius: 3px;
  list-style: none;
  margin-left: 0px;
  padding: 3px 3px 3px 16px;
  font-size: var(--font-reduce-2);
  position: relative;
  white-space: nowrap;
}

.select-flyout-menu ul li.flashing:hover,
.select-flyout-menu ul li.unselectable:hover,
.select-flyout-menu ul li.disabled:hover,
.select-flyout-menu.closing.blinking ul li:hover {
  background-color: inherit;
}

.select-flyout-menu ul li:hover,
.select-flyout-menu.closing ul li:hover {
  background-color: #2280ffbb;
}

.select-flyout-menu ul li.disabled {
  color: var(--app-subtle-color);
}

.select-flyout-menu ul li.selected::before {
  content: "✓";
  padding-left: 0px;
  padding-right: 2.5px;
  margin-left: -11px;
  font-size: var(--font-reduce-1);
}

.select-flyout-menu ul li.expandable::after {
  content: "›";
  text-align: right;
  position: absolute;
  right: 3px;
  font-size: var(--font-increase-1);
  margin-top: -1px;
}

.select-flyout-menu ul li.expandable div.submenu {
  display:none;
}

.select-flyout-menu ul li.expandable:hover div.submenu {
  display:inherit;
}

.alert-color {
  color: var(--app-failure-color);
}

.bold {
  font-weight: bold;
}

@keyframes fade-in {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

.fade-in {
  animation: fade-in .45s;
}

.loading-text {
  font-size: 1.5em;
  color: var(--app-subtle-color);
}

.nowrap {
  white-space: nowrap;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-fill {
  flex: 1 1 auto;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: .5rem;
  margin-top: 0;
  box-sizing: border-box;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

div.input-group {
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: .5rem;
  margin-top: .5rem;
  position: relative;
  width: 100%;
  font-size: var(--font-reduce-1);
}

.input-group span.input-group-text {
  align-items: center;
  border-radius: var(--form-border-radius);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid var(--general-border-color);
  line-height: 1.5;
  white-space: nowrap;
  padding: .375rem .75rem;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 500;
}

.input-group span.input-group-text .input-row-label {
  margin-right: 0;
}

.input-group input[type="text"] {
  border-radius: var(--form-border-radius);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
  width: 1%;
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
  font-size: 1.07em;
}