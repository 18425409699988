.reports-page-nav {
  display: flex;
  align-items: center;
}

.reports-page-pillbox {
  width: 100%;
  margin-right: 15px;
}

.report-run-failed {
  color: var(--app-failure-color);
}

.report-run-success {
  color: var(--app-green);
}