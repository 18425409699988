.rel-time-picker-section-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: .25rem;
  padding: .25rem;
  font-size: var(--font-increase-1);
  font-weight: 600;
}

.rel-time-button-group {
  margin-right: 10px;
}

.custom-rel-time-pane {
  display: flex;
  margin: .5rem 1rem;
  padding: .5rem;
  justify-content: center;
  align-items: center;
}
