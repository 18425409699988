.collapse-menu-container {
  z-index: 22;
  position: absolute;
  top: 2px;
  left: -6px;
  box-sizing: border-box;
}

.collapse-menu-icon-area {
  border-radius: 3px;
  padding: 14px !important;
  background-color: none;
  color: var(--app-text-color);
  cursor: pointer;
  min-width: 25px;
  max-width: 25px;
  width: 25px;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease, background-color 0.2s ease;
  display: flex;
  box-sizing: border-box;
}

.collapse-menu-container.collapse-menu-container-touch .collapse-menu-icon-area,
.collapse-menu-container.collapse-menu-container-touch .collapse-menu-icon-area.collapse-menu-icon-area-inactive {
  opacity: .7;
}

.collapse-menu-icon-area-inactive {
  opacity: 0;
}

.collapse-menu-active .collapse-menu-icon {
  color: var(--app-highlight-color);
}

.collapse-menu-touch {
  opacity: .7;
}

.collapse-menu-icon-area:hover {
  background-color: var(--general-hover);
  border-radius: 3px;
}

.collapse-menu {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}

.collapse-menu-mask {
  transition: opacity 0.25s ease;
}

.collapse-menu {
  background-color: var(--menu-bg-color);
  box-shadow: 0 0 25px 15px var(--modal-shadow);
  border-radius: var(--panel-border-radius);
  border: 1px var(--general-border-color) solid;
  transition: all 0.25s ease;
}

.collapse-menu ul {
  box-sizing: border-box;
  display: block;
  margin-top: 5px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-left: 0px;
}

.collapse-menu ul hr.separator {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.collapse-menu ul li {
  box-sizing: border-box;
  position: relative;
  padding: 5px 16px 5px 12px;
  list-style: none;
  margin-left: 0px;
  font-size: var(--font-reduce-1);
  cursor: default;
}

.collapse-menu ul li:last-child {
  border-bottom-left-radius: var(--panel-border-radius);
  border-bottom-right-radius: var(--panel-border-radius);
  padding-bottom: 8px;
}

.collapse-menu ul li.selectable:hover {
  background: var(--menu-hover-bg-color);
}

.collapse-menu-check-area {
  width: 28px;
  box-sizing: border-box;
  display: inline-block;
}

.collapse-menu ul li.selectable:hover {
  background: var(--menu-hover-bg-color);
}

div.collapse-menu ul li {
  position: relative;
  padding: .5em 16px .5em 4px;
}

.menu-icon {
  height: 16px;
  padding: 0px;
  margin-left: 4px;
  cursor: pointer;
}

div.collapse-menu ul li.collapse-menu-header {
  display: flex;
  padding-top: 0px;
  padding-bottom: 5px;
}

.collapse-menu-icon-area.collapse-menu-icon-area-active {
  position: absolute;
  top: -5px;
  left: 0px;
  opacity: 1;
  cursor: pointer;
  color: var(--app-highlight-color);
  border-top-left-radius: var(--panel-border-radius);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.collapse-menu-icon-area.collapse-menu-icon-area-active:hover {
  background-color: var(--general-hover);
  opacity: 1;
}

.collapse-menu-header-label {
  color: var(--app-subtle-color);
  margin-left: 25px;
}
