.chart-library-container {
  display: flex;
  align-content: center;
}

.library-chart-placeholder {
  min-width: 300px;
  border: 3px var(--form-row-hover) solid;
  padding-left: 8px;
  padding-right: 8px;
  height: 550px;
  border-radius: var(--form-border-radius) !important;
  position: relative;
}

.library-chart-placeholder-text {
  position: absolute;
  top: 150px;
  margin-left: 10px;
  display: flex;
}
