.input-control-toggleswitch-container {
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  padding: 4px 6px 4px 6px;
}

input[type="checkbox"].input-control.input-control-toggleswitch {
	height: 0;
	width: 0;
	visibility: hidden;
}

label.input-control-toggleswitch-label {
	cursor: pointer;
	text-indent: -9999px;
	width: 44px;
	height: 26px;
	background: var(--general-border-color);
	display: block;
	border-radius: 15px;
	position: relative;
}

.input-control-toggleswitch-container.input-control-toggleswitch-container-sm label.input-control-toggleswitch-label {
	width: 36px;
	height: 20px;
}

label.input-control-toggleswitch-label:hover {
  box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.15);
}

label.input-control-toggleswitch-label:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 2px;
	width: 22px;
	height: 22px;
	background: white;
	border-radius: 11px;
	transition: 0.2s;
}

.input-control-toggleswitch-container.input-control-toggleswitch-container-sm label.input-control-toggleswitch-label:after {
	width: 16px;
	height: 16px;
}

input.input-control.input-control-toggleswitch:checked + label.input-control-toggleswitch-label {
	background: var(--app-link-color);
}

input.input-control.input-control-toggleswitch:checked + label.input-control-toggleswitch-label:after {
	left: calc(100% - 2px);
	transform: translateX(-100%);
}

label.input-control-toggleswitch-label:active:after {
	width: calc(100% - 4px);
}
