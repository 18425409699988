:root {
  --list-box-line-height: 28px;
}

.list-box-item {
    line-height: var(--list-box-line-height);
    font-size: .95em;
    padding-left: 10px;
    display: flex;
    align-content: center;
    cursor: pointer;
    white-space: nowrap;
    overflow-x: hidden;
    margin-top: 3px;
}

.list-box-item:hover {
    background-color: var(--general-hover);
}

.list-box-item-active {
    font-weight: 600;
}

.list-box-child-item-active {
    background-color: #6663;
}

.list-box-child-item-active .list-box-item-icon svg {
    color: var(--app-green);
}

.list-box-item-child {
    padding-left: 24px;
    margin-top: 0px;
}

.list-box-item.list-box-item-disabled {
    color: var(--app-subtle-color);
    cursor: default;
}

.list-box-item.list-box-item-disabled:hover {
    background-color: inherit;
}

.list-box-item-children {
    transition: height 120ms;
    overflow-y: hidden;
}
