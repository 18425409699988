.select-row-select, .select-row-select.input-control {
  appearance: none;
  background-position-y: 13px;
  background-position-x: calc(100% - 8px);
  background-size: 12px 10px;
  background-color: var(--input-bg-color);
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23545a60%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  color: var(--form-control-color);
  font-size: 1em;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  padding: 4px 24px 4px 10px;
  height: 38px;
  width: calc(100% - 95px);
  border: 1px var(--general-border-color) solid;
  border-radius: var(--form-border-radius);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
  width: 1%;
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
}