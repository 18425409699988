.modal-user-info {
  padding: 1rem;
  line-height: 24px;
  font-size: var(--font-increase-1);
}

.signed-in-content, .caution-content, .rotated-content {
  padding: 10px 15px 0px 15px;
}

.modal-user-info-header-icon {
  margin-right: .5rem;
}