div.whats-this-container .whats-this-icon {
  height: 20px;
  color: var(--app-subtle-color);
  cursor: pointer;
}

div.whats-this-container .whats-this-icon:hover {
  color: var(--app-link-color);
}

div.whats-this-container {
  position: relative;
}

div.whats-this-container .whats-this-tooltip {
  position: fixed;
  z-index: 300;
  margin-top: -70px;
  margin-left: 30px;
  background-color: var(--form-row-hover);
  min-height: 80px;
  width: 225px;
  padding: 18px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  box-shadow: var(--pane-box-shadow);
  line-height: 1.4;
  white-space: normal;
}

div.whats-this-container .whats-this-tooltip-hover {
  display: none;
}

div.whats-this-container:hover .whats-this-tooltip-hover {
  display: flex;
}

.whats-this-tooltip-closer {
  display: flex;
  position: fixed;
  margin-top: -89px;
  margin-left: 185px;
  border: var(--general-border);
  padding: 3px 4px 3px 4px;
  border-radius: 3px;
}

.whats-this-tooltip-closer-icon {
  height: 12px;
}

.whats-this-tooltip-closer-icon:hover {
  color: var(--app-link-color);
  cursor: pointer;
}