
ul.nav-menu {
  margin-bottom: 5px;
  margin-left: 0px;
  padding-left: 0px;
  margin-top: 0px;
}

ul.nav-menu li {
  position: relative;
  padding: 10px 0px 10px 10px;
  cursor: pointer;
  list-style: none;
  margin-left: 0px;
}

ul.nav-menu a.selected li {
  font-weight: 600;
  background: var(--nav-hover-bg-color);
}

ul.nav-menu a,
ul.nav-menu a:hover,
ul.nav-menu a:active {
  color: var(--body-color);
  text-decoration: none;
}


ul.nav-menu li:hover {
  background: var(--nav-hover-bg-color);
}
