div.modal-generate-report-body {
  padding: 15px 10px;
  min-height: 250px;
  max-height: 450px;
  overflow-y: auto;
}

div.modal-generate-report-body .generate-report-section-header {
  font-weight: 600;
  margin-bottom: 15px;
  margin-left: 5px;
}

div.modal-generate-report-body .generate-report-section-header.short {
  margin-bottom: 5px;
}

div.modal-generate-report-body .generate-report-section-content {
  margin-left: 10px;
}

.generate-report-type-description-label {
  color: var(--app-subtle-color);
  margin-left: 10px;
  font-size: var(--font-reduce-1);
  /* margin-top: 12px; */
}

.generate-report-type-description-label-indented {
  color: var(--app-subtle-color);
  margin-left: 40px;
  font-size: var(--font-reduce-1);
  margin-top: 10px;
}

.generate-report-element-label {
  font-weight: 600;
  margin-bottom: 5px;
  margin-left: 5px;
}

.status-banner.report-save-success,
.status-banner.report-save-failure {
  margin-top: 20px;
}

div.modal-generate-report-body .notice {
  margin-top: 15px;
}

div.modal-generate-report-body .notice .caution {
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 1.7;
}
