.radio-wrapper {
  height: 30px;
  display: flex;
  margin-right: 20px;
  align-items: center;
  width: 64px;
  min-width: 64px;
}

.radio-wrapper-hover-area {
  display: flex;
  padding: 4px;
  cursor: pointer;
  align-items: center;
}

.radio-wrapper-hover-area:hover {
  background: var(--menu-hover-bg-color);
  border-radius: var(--input-border-radius);
}

.radio-wrapper .radio-label {
  font-weight: 500;
  padding-left: 8px;
  font-size: var(--font-reduce-2);
  cursor: pointer;
  margin-right: 4px;
}

input[type="radio"].input-control {
  cursor: pointer;
  appearance: none;
  border: 1px var(--general-border-color) solid;
  border-radius: 25px;
  height: 16px;
  width: 16px;
  max-width: 16px;
  max-height: 16px;
  box-sizing: border-box;
  margin: 0px;
}

input[type="radio"].input-control:active,
input[type="radio"].input-control:active:checked {
  border-color: var(--app-blue);
  background: none;
}

input[type="radio"].input-control:checked {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
  background-color: var(--app-link-color);
  border-color: var(--app-blue);
}

input[type="radio"].input-control:hover {
  box-shadow: inset 0 0 0 10em rgba(104, 153, 204, 0.15);
}