.select-wrapper {
  display: flex;
}

.select-wrapper .select-label {
  font-weight: 500;
  line-height: 30px;
  height: 30px;
  margin-right: 5px;
  font-size: var(--font-reduce-2);
  text-align: right;
  white-space: nowrap;
}

.select-label.required::before {
  content: "* ";
  color: var(--app-failure-color);
  font-weight: bold;
}

select.input-control,
.input-control-select {
  color: var(--body-color);
  height: 30px;
  padding-left: 6px;
  padding-bottom: 2px;
  border-radius: var(--input-border-radius);
  border: 1px solid var(--input-border-color);
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  appearance: none;
  background-position-y: 9px;
  background-color: var(--input-bg-color);
  background-repeat: no-repeat;
  background-position-x: calc(100% - 7px);
  background-size: 12px 10px;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23545a60%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  font-family: var(--body-font);
  font-size: var(--font-reduce-2);
  overflow-wrap: normal;
  min-height: 32px;
  padding-right: 24px;
  padding-left: 8px;
  display: block;
}

select.input-control:disabled,
.input-control-select:disabled {
  color: var(--app-subtle-color);
  cursor: default;
  background-position-y: 9px;
  background-color: inherit;
}

select.input-control:hover
{
    border-color: var(--customer-selector-border);
}

select.input-control:disabled:hover {
    border-color: var(--general-border-color);
}
