.expand-panel {
  border: 1px solid var(--general-border-color);
  background-color: var(--input-bg-color);
  min-height: 30px;
  border-radius: var(--input-border-radius);
  width: 100%;
  font-size: .9em;
  margin-top: 5px;
  cursor: default;
}

.expand-panel-header:hover {
  cursor: pointer;
}

.expand-panel-open .expand-panel-title {
  font-weight: 700;
}

.expand-panel-title {
  flex-grow: 1;
}

.expand-panel-header {
  display: flex;
  padding: .5rem 0 .5rem .5rem;
  align-items: center;
  margin-right: .25rem;
}



.expand-panel hr {
  margin: 0 .25rem .25rem !important;
}