.modal-relocate-chart {
  padding: 1rem 1rem .5rem 1rem;
}

.modal-relocate-chart-body {
  line-height: 1.9;
  padding: .5rem;
  margin-bottom: 15px;
  min-height: 175px;
}

.status-banner.chart-relocate-in-progress {
  padding-top: 20px;
  font-size: var(--font-increase-4);
}

div.input-group.chart-relocate-description {
  margin-bottom: 1rem;
}

div.modal-relocate-name-conflict {
  text-align: center;
  margin-top: 15px;
  color: var(--app-caution-color);
}
