.chart-view {
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  border: 3px var(--form-row-hover) solid;
  border-radius: var(--form-border-radius) !important;
  box-sizing: border-box;
}

.chart-view-fs {
  background-color: var(--body-bg-color);
  position: absolute;
  height: calc(100vh - var(--header-height) - var(--footer-height));
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
  width: 100vw;
  min-width: 100vw;
  top: var(--header-height);
  left: 0;
  border: none;
  padding-top: 5px;
  box-sizing: border-box;
}

.chart-view-builder {
  max-height: 550px;
  max-width: 725px;
}

.chart-view-loading,
.chart-view-no-data {
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.chart-container {
  height: 100%;
  position: relative;
}

div.chart-view div.chart-container:hover div.fs-toggle {
  opacity: .7;
}

div.chart-view div.chart-container:hover div.fs-toggle.fs-toggle-active,
div.fs-toggle.fs-toggle-touch.fs-toggle-active {
  opacity: 1;
}

div.chart-view div.chart-container:hover div.fs-toggle:hover {
  background-color: var(--general-hover);
}

div.chart-view div.chart-container:hover div.chart-menu  div.collapse-menu-icon-area {
  opacity: .7;
}

div.chart-view div.chart-container:hover div.chart-menu  div.collapse-menu-icon-area-active {
  opacity: 1;
}

.chart-controls-row {
  display: flex;
  align-items: center;
  margin-top: 12px;
  color: var(--app-subtle-color);
  font-size: var(--font-reduce-1);
}
