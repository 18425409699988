
.timepicker-select,
.timepicker-select:active,
.timepicker-select:focus {
  background-position-x: calc(100% - 5px);
  padding-right: 0px;
  width: 54px;
  font-size: var(--font-reduce-1);
  text-align: center;
  padding-right: 22px;
}

.timepicker-select:last-child {
  margin-right: 0px;
}


.timepicker-select-group .timepicker-select-day,
.timepicker-select-group .timepicker-select-day:active,
.timepicker-select-group .timepicker-select-day:focus {
  width: 49px !important;
  margin-right: 5px;
}

.timepicker-select-group .timepicker-select-year,
.timepicker-select-group .timepicker-select-year:active,
.timepicker-select-group .timepicker-select-year:focus {
  width: 66px !important;
  margin-right: 5px;
}

.timepicker-select-group .timepicker-select-month,
.timepicker-select-group .timepicker-select-month:active,
.timepicker-select-group .timepicker-select-month:focus {
  width: 105px !important;
  margin-right: 5px;
}

.timepicker-select-group .timepicker-select-hour,
.timepicker-select-group .timepicker-select-hour:active,
.timepicker-select-group .timepicker-select-hour:focus {
  margin-right: 5px;
}

.timepicker-select-group {
  display: flex;
}