.modal-edit-chart-colors-body {
  min-height: 300px;
  max-height: 500px;
  padding: 1rem 1rem 2rem 1rem;
  overflow-y: auto;
}

.edit-chart-colors-pillbox {
  margin-bottom: 15px;
}

.edit-chart-colors-list {
  margin-left: 5px;
  margin-right: 5px;
}

.editable-chart-color-entry {
  display: flex;
  align-items: center;
  border-bottom: var(--general-border);
  min-height: 28px;
  max-height: 28px;
  height: 28px;
}

.editable-chart-color-entry:hover {
  background-color: var(--form-row-hover);
}

.editable-chart-color-entry-label {
  font-weight: 500;
  margin-left: 10px;
  width: 100%;
  font-size: var(--font-reduce-1);
}

.editable-chart-color-swatch {
  min-height: 18px;
  max-height: 18px;
  height: 18px;
  min-width: 24px;
  max-width: 24px;
  width: 24px;
}

.editable-chart-color-swatch-lg {
  min-height: 28px;
  max-height: 28px;
  height: 28px;
  min-width: 32px;
  max-width: 32px;
  width: 32px;
  margin: 10px;
  border: 2px solid #00000000;
  cursor: pointer;
}

.editable-chart-color-swatch-lg.editable-chart-color-swatch-selected {
  border: 2px solid var(--app-highlight-color);
  cursor: default;
}

.chart-color-editor-palette {
  display: flex;
  flex-wrap: wrap;
}

.editable-chart-color-entry-edit,
.editable-chart-color-entry-delete {
  cursor: pointer;
}

.editable-chart-color-entry-edit:hover,
.editable-chart-color-entry-delete:hover {
  color: var(--app-highlight-color);
}

.editable-chart-color-entry-edit {
  margin-right: 12px;
}
.editable-chart-color-entry-delete {
  margin-right: 3px;
}

.edit-chart-colors-list-section-header {
  font-weight: 600;
  padding-bottom: 8px;
  border-bottom: var(--general-border);
}

.chart-color-forgetter {
  text-align: center;
}

.editable-chart-color-entry-forget-row {
  margin-top: 30px;
  display: flex;
  margin-left: 15px;
  justify-content: left;
  width: fit-content;
}

.chart-color-forgetter-prompt {
  font-size: var(--font-increase-1);
  font-weight: 500;
  margin-top: 20px;
}

.editable-chart-color-entry-forget-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.chart-color-editor-label {
  font-weight: 500;
  font-size: var(--font-increase-1);
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}