
/* Context Menu */

.context-menu {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}

.context-menu-mask {
  transition: opacity 0.25s ease;
  z-index: 23;
  position: fixed;
  top: 38px;
  width: 265px;
}

.context-menu {
  background-color: var(--menu-bg-color);
  box-shadow: 0 0 25px 15px var(--modal-shadow);
  border-radius: var(--panel-border-radius);
  border: 1px var(--general-border-color) solid;
  transition: all 0.25s ease;
}

.context-menu ul {
  box-sizing: border-box;
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 0px;
  padding-left: 0px;
}

.context-menu hr {
  margin-top: 2px;
  margin-bottom: 2px;
}

.context-menu ul li {
  box-sizing: border-box;
  position: relative;
  padding: 5px 16px 5px 12px;
  list-style: none;
  margin-left: 0px;
  font-size: var(--font-reduce-1);
  cursor: default;
}

.context-menu ul li:last-child {
  border-bottom-left-radius: var(--panel-border-radius);
  border-bottom-right-radius: var(--panel-border-radius);
}

.context-menu ul li.selectable:hover {
  background: var(--menu-hover-bg-color);
}

.context-menu-check-area {
  width: 28px;
  box-sizing: border-box;
  display: inline-block;
}

.context-menu ul li.selectable:hover {
  background: var(--menu-hover-bg-color);
}

div.settings-menu ul li {
  position: relative;
  padding: .5em 16px .5em 4px;
}

.menu-icon {
  height: 16px;
  padding: 0px;
  margin-left: 4px;
  cursor: pointer;
}

.context-menu-header-label {
  color: var(--app-subtle-color);
  margin-left: 25px;
}
