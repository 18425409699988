.modal-delete-chart {
  padding: 1rem 1rem .5rem 1rem;
}

.modal-delete-chart-body {
  line-height: 1.9;
  padding: .5rem;
  margin-bottom: 15px;
  min-height: 195px;
  text-align: center;
}

.status-banner.chart-delete-in-progress {
  padding-top: 20px;
  font-size: var(--font-increase-4);
}
