.feedback-form-body {
  min-height: 522px;
  max-height: 522px;
  height: 522px;
}

.feedback-form-intro {
  padding: 20px 15px 20px 15px;
  font-size: .95em;
}

.feedback-form-intro:first-child {
  padding-bottom: 5px;
}

.feedback-form-input-list {
  padding: 0px 0px 15px 15px;
}

.feedback-form-input-header {
  font-weight: 600;
  padding-bottom: 10px;
  margin-top: 25px;
  font-size: .97em;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.feedback-form-input-control {
  margin-left: 10px;
  width: 320px;
}

.feedback-form-remarks-input {
  height: 145px;
  max-height: 155px;
  min-height: 135px;
}


.feedback-form-confirmation {
  font-weight: 600;
  font-size: 1.25em;
  height: 100%;
  align-content: center;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
  line-height: 1.5;
}