.button-group {
  display: flex;
  justify-content: space-between;
}

.button-group .button-group-element {
    display: flex;
    align-items: center;
  }

.button-group .button-group-element:last-child {
  margin-right: 0px;
}

.button-group input {
  appearance: none;
}

.button-group.pillbox {
  background-color: var(--form-row-hover);
  width: fit-content;
  border-radius: 8px;
}

.button-group.pillbox .button-group-button {
  border: none;
  font-weight: normal;
  font-size: 1em;
  margin: 3px;
  border-radius: 8px;
  color: var(--body-color);
  max-height: 24px;
}

.button-group.pillbox .button-group-button:hover,
.button-group.pillbox .button-group-button:active,
.button-group.pillbox .button-group-button:active:hover {
  box-shadow: inset 0 0 0 10em rgba(128, 128, 128, 0.1);
  background-color: inherit;
  color: var(--body-color);
}

.button-group.pillbox .button-group-button.button-group-button-selected,
.button-group.pillbox .button-group-button.button-group-button-selected:active,
.button-group.pillbox .button-group-button.button-group-button-selected:hover {
  background-color: var(--body-bg-color) !important;
  box-shadow: none;
  font-weight: 600;
  color: var(--body-color);
  cursor: default;
}

.button-group.wide,
.button-group.pillbox.wide {
  width: 100%;
}

.button-group.wide .button-group-button,
.button-group.pillbox.wide {
  width: 100%;
}

.button-group.wide .button-group-element {
  width: 100%;
}