.chart-builder-panel {
  min-width: 260px;
  width: 260px;
  box-sizing: border-box;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 3px;
  height: calc(100vh - 166px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 8px;
}

.add-query-section {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.reset-query-options-section {
  margin-top: .5rem;
  text-align: center;
}
