.input-control-checkbox-container {
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  padding: 4px 6px 4px 6px;
}

.input-control-checkbox-container.short {
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  padding: 0px 6px 0px 6px;
}

.input-control-checkbox-container:hover {
  background: var(--menu-hover-bg-color);
  border-radius: var(--input-border-radius);
}

.input-control-checkbox-label {
  padding-left: 6px;
  font-size: var(--font-reduce-2);
  cursor: pointer;
}

span.input-control-checkbox-label-disabled {
  color: var(--app-subtle-color);
  cursor: default;
}

input[type="checkbox"].input-control.input-control-checkbox {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: var(--input-border-radius);
  border: var(--general-border);
  background-color: var(--body-bg-color);
  cursor: pointer;
}

input[type="checkbox"].input-control.input-control-checkbox:hover {
  box-shadow: inset 0 0 0 10em rgba(104, 153, 204, 0.15);
}

input[type="checkbox"].input-control.input-control-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
  background-color: var(--app-blue);
  border: none;
}

input[type="checkbox"].input-control.input-control-checkbox:active,
input[type="checkbox"].input-control.input-control-checkbox:active:checked {
  border: 1px solid var(--app-blue);
  background-image: none;
  background-color: var(--body-bg-color);
}
