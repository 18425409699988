.carat {
  stroke: #545a60;
  height: 10px;
  width: 12px;
  margin-right: 3px;
}

.carat-left {
  stroke: #545a60;
  height: 10px;
  width: 12px;
  margin-right: 4px;
}
