.abs-time-picker-section-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: .25rem;
  padding: .25rem;
  font-size: var(--font-increase-1);
  font-weight: 600;
}

.abs-time-picker-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: .25rem .5rem .5rem .5rem;
  padding: 1rem;
}

.abs-time-picker-tz-label {
  text-align: center;
  color: var(--app-subtle-color);
  border-top: var(--general-border);
  padding: 1rem;
}
